'use strict';

const SiteConstants = require('constants/SiteConstants');
const menu = require('./menu');
const largeBreakpoint = SiteConstants.BreakpointSizes.lg;
const debounce = require('lodash/debounce');
const endpoint = $('.suggestions-wrapper').data('url');
const minChars = 3;
const UP_KEY = 38;
const DOWN_KEY = 40;
const DIRECTION_DOWN = 1;
const DIRECTION_UP = -1;
const DEBOUNCE_TIME = 300;

const searchOverlay = {
    open: "search-overlay-open",
    resultsIn: "search-results-in"
}

const $header = $('#top-header');
const $input = $header.find('.search-field');
const $inputNoResults = $('.result-count').find('.search-field');
const $trigger = $header.find('.js-search-trigger');

const $cancelSearch = $('.site-search-cancel');
const $html = $('html');
const $searchOverlayEl = $('.search-overlay');
const $simpleSearchForm = $('form[name="simpleSearch"]');
const debounceSuggestions = debounce(getSuggestions, DEBOUNCE_TIME);

// non jquery
const focusableElements = '[href], input, [tabindex]:not([tabindex="-1"])';
const searchOverlayEl = document.querySelector('.search-overlay');
let productWrapper;

let queryText = '';
let initialDom = '';
let resizeDelay, startX, scrollLeft;
let xhrQuery = null;
let mouseDown = false;

/**
 * Sets horizontal scroll variable - See function initializeHorizontalScroll
 * 
 */
function startDragging(e) {
    mouseDown = true;
    document.body.style.cursor = 'grab';
    startX = e.pageX - productWrapper.offsetLeft;
    scrollLeft = productWrapper.scrollLeft;
}

/**
 * Sets horizontal scroll variable. Resets is-scrolling variable - See function initializeHorizontalScroll
 * 
 */
function stopDragging() {
    mouseDown = false;
    setTimeout(() => {
        productWrapper.classList.remove('is-scrolling');
      }, 10);
    document.body.style.cursor = 'auto';
}

/**
 * Allow scroll event
 * 
 * @description Sets up and initalizes horizontal scroll on product wrapper
 */
function initializeHorizontalScroll() {
    if (!productWrapper || window.isMobile()) return;

    productWrapper.addEventListener('mousemove', (e) => {
        e.preventDefault();
        if(!mouseDown) { return; }
        productWrapper.classList.add('is-scrolling');
        const x = e.pageX - productWrapper.offsetLeft;
        const scroll = x - startX;
        productWrapper.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    productWrapper.addEventListener('mousedown', startDragging, false);
    productWrapper.addEventListener('mouseup', stopDragging, false);
    productWrapper.addEventListener('mouseleave', stopDragging, false);
}

function setSearchPosition() {
    if ($header.hasClass(searchOverlay.open)) {
        const $headerHeight = $header.outerHeight();
        
        const overlayPosition = [
            `top: ${$headerHeight}px`,
            `height: calc(100vh - ${$headerHeight}px)`
        ].join(';');
        
        $searchOverlayEl.attr('style', overlayPosition);
    }
};

/**
 * Handles Focus Event from search input
 * 
 * @param {Object} el - Trigger element or Search Input element
 * @param {Event} event - Which Eevent occured: trigger click or input focus
 */
function handleTriggerEvent(el) {
    if ($header.hasClass(searchOverlay.open)) {
        handleCloseSearchEvent();
    } else {
        if (window.isMobile()) {
            menu.methods.toggleDrawer('close', true);
        }
        
    
        // To bring back popular search term look up, uncomment this code.
        // if (!!!initialDom || initialDom === '') {
        //     getPopularSearchTerms();
        // };
    
        $html.addClass('lock-scroll');
        $header.addClass(searchOverlay.open);
        setSearchPosition();
        $(el.target).addClass('search-field-active');
        $input.focus();
    }
    
};

/**
 * Handles close search event
 */
function handleCloseSearchEvent() {
    $html.removeClass('lock-scroll');
    $input.blur();
    $header.removeClass(searchOverlay.open);
    $('.search-field').removeClass('search-field-active');
};

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Event} e - Key event
 * @param {Object} scope - Search input field DOM element
 */
function handleInputKeyEvent(e, scope) {
     // Capture Down/Up Arrow Key Events
     switch (e.which) {
        case DOWN_KEY:
            handleArrow(DIRECTION_DOWN);
            e.preventDefault(); // prevent moving the cursor
            break;
        case UP_KEY:
            handleArrow(DIRECTION_UP);
            e.preventDefault(); // prevent moving the cursor
            break;
        case 9:
        case 16:
            break;
        default:
            debounceSuggestions(scope, e);
    }
};

/**
 * Bolds any matching text within the returned list
 */
function boldMatchingQueryText() {
    const $term = $searchOverlayEl.find('.term');
    $term.each(function() {
        const termHtml = $(this).html();
        $(this).html(termHtml.replace(new RegExp(queryText, 'ig'), `<strong class="text-capitalize">${queryText}</strong>`));
    });
};

/**
 * Responsive product tiles. Swaps from condensed view to default view
 */
function responsiveProductTiles() {
    if ($('.search-overlay-product').length > 0) {
        if (window.innerWidth >= largeBreakpoint) {
            $('.search-overlay-product').find('.product-tile').removeClass('condensed').addClass('default');
        } else {
            $('.search-overlay-product').find('.product-tile').removeClass('default').addClass('condensed');
        }
    }
};

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $searchOverlayEl.find('.search-overlay-suggestions-wrapper');
};

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    $searchOverlayEl.removeClass(searchOverlay.resultsIn);
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    if (typeof (response) !== 'object') {
        $suggestionsWrapper.append(response);
        responsiveProductTiles();
        if ($('.suggestions-product-wrapper').length > 0 && $('.suggestions-product-wrapper').css("visibility") === "visible") {
            $searchOverlayEl.addClass(searchOverlay.resultsIn);
            productWrapper = document.querySelector('.suggestions-product-wrapper');
            initializeHorizontalScroll();
        }
        boldMatchingQueryText();

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.search-overlay-list .suggestions-item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    }
};

/**
 * Retrieve popular Search Terms
 *
 * @param {Object} scope - Search field DOM element
 */
function getPopularSearchTerms() {
    if (!!document.querySelector('[data-search-popular-endpoint]')) {
        const popularListEl = document.querySelector('[data-search-popular-endpoint]');
        const popularEndpoint = popularListEl.dataset.searchPopularEndpoint;
        var $suggestionsWrapper = getSuggestionsWrapper(this).empty();
        $.ajax({
            url: popularEndpoint,
            method: 'GET',
            success: (response) => {
                initialDom = response;
                $suggestionsWrapper.append(response);
            }
        });
    }
};

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        if (xhrQuery !== null) {
            xhrQuery.abort();
        };

        queryText = $(scope).val();
        xhrQuery = $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {}
        });
    } else if ($(scope).val().length === 0) {
        getSuggestionsWrapper(scope).empty().append(initialDom);
    }
};

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.search-overlay-list .suggestions-item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
};

/**
 * Traps focus within search overlay modal
 *
 * @param {Event} e - keyboard event
 */
function trapFocus(e) {
    const firstFocusableElement = $input;
    const focusableContent = searchOverlayEl.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];
    if (lastFocusableElement) {
        if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
            }
        } else {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                e.preventDefault();
            }
        }
    }
}

/**
 * Wrapper function for delay calls on resize
 * 
 * @description Call functions here to be called during resize event
 * 
 */
function delayedResizeFunctions() {
    responsiveProductTiles();
    initializeHorizontalScroll();
    setSearchPosition();
}

/**
 *
 * @description Add event Listeners
 */
 function addEventListeners() {
    $trigger.on('click', (e) => handleTriggerEvent(e));
    $cancelSearch.on('click', handleCloseSearchEvent);

    $('body').on('click', '.js-trigger-search-submit', function (){
        $simpleSearchForm.submit();
    });

    $input.each(function () {
        $(this).on('keyup', function (e) {
            $(this).addClass('search-field-active');

            if (!$(this).val()) {
                $(this).parent('form').addClass('search-input-empty');
                $searchOverlayEl.removeClass(searchOverlay.resultsIn);
            } else {
                $(this).parent('form').removeClass('search-input-empty');
            }

            // If user is tabbing
            if ( !$header.hasClass(searchOverlay.open) && (e.key === 'Tab' || (e.shiftKey && e.key === 'Tab')) ) {
               return;
            }

            handleInputKeyEvent(e, this);
        });
    });

    $inputNoResults.each(function () {
        $(this).on('keyup', function () {
            if (!$(this).val()) {
                $(this).parent('form').addClass('search-input-empty');
            } else {
                $(this).parent('form').removeClass('search-input-empty');
            }
        });
    });

    // set product tile style when resizing
    window.addEventListener('resize', function() {
        clearTimeout(resizeDelay);
        resizeDelay = setTimeout(delayedResizeFunctions, 300);
    });


    window.addEventListener('keydown', function(e){
        if ($header.length === 0) return;
        if (e.key === 'Escape'||e.key === 'Esc'){
            if (!$header.hasClass(searchOverlay.open)) return;
            e.preventDefault();
            handleCloseSearchEvent();
        };

        if (e.key === 'Tab') {
            trapFocus(e);
        };
    }, true);

    $simpleSearchForm.submit(function (e) {
        var suggestionsList = $('.suggestions .item');
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
    });

    document.addEventListener('click', function(e) {
        if (!$header.hasClass(searchOverlay.open)) return;
        if ( !searchOverlayEl.contains(e.target) && !e.target.classList.contains('js-search-trigger') ) {
            handleCloseSearchEvent();
        }
    });

    $searchOverlayEl.find('.backdrop').on('click', function() {
        if (!$header.hasClass(searchOverlay.open)) return;
        handleCloseSearchEvent();
    });

     // Disables click event if user is dragging/scrolling
     document.body.addEventListener('click', function( event ){
        if (!productWrapper) return;
        if (productWrapper.contains(event.target) && productWrapper.classList.contains('is-scrolling')) {
            event.preventDefault();
        }
    });
};

module.exports = {
    addEventListeners: addEventListeners,
    handleCloseSearchEvent: handleCloseSearchEvent
};