'use strict';

module.exports = function () {
  var selector = '.js-read-more-link, .js-read-less-link'
  $('body').on('click', selector, function (e) {
      var $parent = $(this).parent('.read-more-text');
      if (e.target.classList.contains('js-read-more-link')) {
        $(this).addClass('d-none');
        $parent.find('.read-more-post-script, .js-read-less-link').removeClass('d-none');
      } else {
        $(this).addClass('d-none');
        $parent.find('.read-more-post-script').addClass('d-none');
        $parent.find('.js-read-more-link').removeClass('d-none');
      }
  });
};
