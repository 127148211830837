'use strict';

var SiteConstants = require('constants/SiteConstants');

/**
 * @description Event listener to close custom drawer modal
 **/
function closeDrawerModal() {
    $('body').on('click', '[data-custom-drawer-dismiss]', function() {
        var $drawerModal = $(this).parents('.modal-drawer-container');
        $drawerModal.removeClass('show');

        setTimeout(() => {
            $drawerModal.modal('hide');
        },SiteConstants.TransitionSpeed);
    });
}

module.exports = {
    closeDrawerModal: closeDrawerModal
}