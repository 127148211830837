'use strict';
var core = require('core/cart/cart');
var base = require('integrations/product/base');
var SiteConstants = require('constants/SiteConstants');
var abSlider = require('../components/slider');

/**
 * Retrieves the relevant pid value (copied from base so we dont load the entire file 2x for this)
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
core.getPidValue = function($el){
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}


core.updateMiniCartCount = function(data) {
    var minicartCountMessage = '';
    if (data &&  data['numItems'] !== undefined) {
        if (data.numItems >= 1) {
            $('.minicart-quantity').removeClass('hidden').empty().text(data.numItems);
            minicartCountMessage = data.resources.minicartCountOfItems;
        } else {
            $('.minicart-quantity').addClass('hidden').empty().text(data.numItems);
            minicartCountMessage = $('.minicart-link').data('defaultMessage');
        }
        $('.minicart-link').attr({
            'aria-label': minicartCountMessage,
            title: minicartCountMessage
        });
        $('.minicart-link .sr-only').empty().text(minicartCountMessage);

        if ($('.minicart .modal .minicart-content').children().length > 0) {
            $('.minicart-title .item-count').empty().text(data.numItems);
        }

        sessionStorage?.setItem?.('cartcount', data.numItems);
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
 core.updateApproachingDiscounts = function (approachingDiscounts, freeShipmentMsg, items) {
    var timestamp_now = window.performance ? Math.round(window.performance.now() * 1000) : Date.now();
    const promotion = {
        enable: function(container) {
            container.data('met', '1');
            container.find('.approaching-discount-progress-bar-inner').css('width', '100%');
            container.removeClass('gwp-unmet');
            container.find('.approaching-discount-subheader').html("<span class='text--green'>Congratulations!</span> <span>You've unlocked your free gift.</span>")

            // All buttons use these attributes...
            var uuid = container.data('lineitem-uuid');
            var pliuuid = container.data('lineitem-pliuuid');
            var quickiew_buttons = container.find('.product-tile-gwp .quickview.btn');
            quickiew_buttons.removeClass('disabled').text('Add');
            quickiew_buttons.each(function(){
                $(this).attr('href',
                    $(this).data('href-base') +
                    '&gwp_uuid=' + uuid +
                    '&gwp_pliuuid=' + pliuuid
                );
            });
        },

        disable: function(container) {
            container.data('met', null);
            container.addClass('gwp-unmet');

            var quickiew_buttons = container.find('.product-tile-gwp .quickview.btn');
            quickiew_buttons.addClass('disabled').text('Locked');
            quickiew_buttons.each(function(){
                $(this).attr('href', $(this).data('href-base'));
            });
        },

        process: function(item, foundhandler, notfoundhandler) {
            var container = $('.minicart .cart').find('#approaching-discount-' + item.promotionuuid);
            if (container.length) {
                var timestamp_existing = parseFloat(container.data('timestamp') || '0');
                if (timestamp_existing <= timestamp_now && foundhandler) {
                    foundhandler(item, container);
                }
            } else if (notfoundhandler) {
                notfoundhandler(item);
            }
        },

        add: function(uuid, id, callout, heading, progress, products, _class, met) {
            var container;
            if (products.length > 1) {
                container = `
                    <div id="approaching-discount-${uuid}" data-promotionid="${id}" date-timestamp="${timestamp_now}" data-progress="${progress}" data-loading="true" data-met="${met}" class="${_class || ''}">
                        <section class="col-12 minicart-slider minicart-slider-gwp">
                            <div class="minicart-slider-header">
                                <div class="slider-minicart-control-container tns-controls" aria-label="Carousel Navigation">
                                    <button type="button" class="icon-chevron-left" data-controls="prev" tabindex="0" ></button>
                                    <div class="slider-minicart-title">${callout}</div>
                                    <button type="button" class="icon-chevron-right" data-controls="next" tabindex="0" ></button>
                                </div>
                            </div>
                            <div class="approaching-discount-progress">
                                <div class="approaching-discount-progress-bar">
                                    <span class="approaching-discount-progress-bar-inner" style="width: ${progress}%"></span>
                                </div>
                                <div class="approaching-discount-subheader">${heading}</div>
                            </div>
                            <div
                                class="slider-container slider-container-minicart slider-bullets-hidden slider-bullets-hidden-md slider-bullets-hidden-lg next-slide-15 next-slide-md-15 next-slide-lg-15"
                                data-slider-autoplay="false"
                                data-slider-gutter="0"
                                data-slider-gutter-md="0"
                                data-slider-gutter-lg="0"
                                data-slider-items="1"
                                data-slider-items-md="1"
                                data-slider-items-lg="1"
                                data-slider-loop="false"
                                data-slider-speed="300"
                                data-slider-mode="carousel"
                            >
                                <div class="slider tns-slider"/>
                            </div>
                        </section>
                    </div>`;
            } else {
                container = `
                    <div id="approaching-discount-${uuid}" data-promotionid="${id}" date-timestamp="${timestamp_now}" data-progress="${progress}" data-loading="true" data-met="${met}">
                        <div class="gwp-single">
                            <h5>${callout}</h5>
                            <div class="approaching-discount-progress">
                                <div class="approaching-discount-progress-bar">
                                    <span class="approaching-discount-progress-bar-inner" style="width: ${progress}%"></span>
                                </div>
                            <div class="approaching-discount-subheader">${heading}</div>
                        </div>
                    </div>`;
            }
            container = $(container);
            container.hide();

            var inserted = false;
            $('.gift-with-purchase-minicart').children('[id^=approaching-discount-]').each(function(){
                var $this = $(this);
                if (progress > parseFloat($this.data('progress'))) {
                    $this.before(container);
                    inserted = true;
                }
            });
            if (!inserted) {
                $('.gift-with-purchase-minicart').append(container);
            }

            var item  = {
                promotionid: id,
                promotionuuid: uuid,
                promotionProgress: progress,
                bonus: {
                    callout: callout,
                    heading: heading,
                    products: products
                }
            };

            var getTile = function(index, _container) {
                if (index < products.length) {
                    $.ajax({
                        url: products[index].urltile,
                        type: 'get',
                        success: function (data) {
                            promotion.process(item, function(_item, __container) {
                                if (products.length > 1) {
                                    __container.find('.slider').append(data);
                                } else {
                                    __container.append(data);
                                }
                                getTile(index + 1, __container);
                            });
                        },
                        error: function (err) {
                            // what to do here...
                        }
                    });
                } else {
                    var $modalSlider = _container.find('.slider-container .slider');
                    if ($modalSlider.length) {
                        $modalSlider.trigger('slider:destroy');
                        abSlider.initializeSliders(_container);
                    }
                    $('body').trigger('cart:update');

                    var conditionmet = _container.data('met');
                    if (conditionmet) {
                        promotion.enable(_container);
                    } else {
                        promotion.disable(_container);
                    }
                    _container.data('loading', false);
                    _container.show();
                }
            };

            getTile(0, container);
        }
    };

    var html = '';
    var approachingDiscountShippingText = '';
    var shippingProgress = 0;
    var shippingPromotionID = '';
    var bonusDiscounts = [];
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            if (item.bonus) {
                bonusDiscounts.push(item.promotionuuid);
                promotion.process(item,
                    function(item, container) {
                        var conditionmet = container.data('met');
                        if (conditionmet) {
                            // The approaching discount condition was previously
                            // met and has now become un-met
                            promotion.disable(container);
                        }
                        container.find('.heading').html(item.bonus.heading);
                        container.find('.approaching-progress').html('progress: ' + item.promotionProgress);
                        container.find('.approaching-discount-progress-bar-inner').css('width', item.promotionProgress+'%');
                        container.find('.approaching-discount-subheader').html(item.bonus.heading);
                        if (!container.data('loading')) {
                            container.show();
                        }
                    },
                    function(item) {
                        promotion.add(
                            item.promotionuuid,
                            item.promotionid,
                            item.bonus.callout,
                            item.bonus.heading,
                            parseFloat(item.promotionProgress),
                            item.bonus.products,
                            'gwp-unmet',
                            false
                        );
                });
            } else if (item.class === 'SHIPPING') {
                approachingDiscountShippingText = item.discountMsg;
                shippingProgress = item.promotionProgress;
                shippingPromotionID = item.promotionid;
            } else {
                 html +=
                 '<div class="single-approaching-discount">' +
                 '<div class="icon-container">' +
                 '<div class="'+ item.iconClass +'"></div>' +
                 '</div>' +
                 '<div class="single-approaching-discount-text">' +
                 item.discountMsg +
                 '</div>' +
                 '</div>';
            }
        });
    }
    $('.approaching-discounts').append(html);

    if ($('.cart').find('.approaching-discounts .single-discount-shipping.d-none').length) {
        $('.cart').find('.approaching-discounts .single-discount-shipping.d-none').next().addClass('single-discount-border-top');
    }

    if (freeShipmentMsg) {
        $('.minicart, .cart').find('.approaching-discount-shipping').find('.approaching-discount-progress-bar-inner').css('width', '100%');
        $('.minicart, .cart').find('.approaching-discount-shipping').find('.approaching-discount-subheader').html(freeShipmentMsg);
    } else if (approachingDiscounts.length > 0 && $('.minicart').find('.approaching-discount-shipping')) {
        $('.minicart, .cart').find('.approaching-discount-shipping').find('.approaching-discount-progress-bar').data('promotionid', shippingPromotionID);
        $('.minicart, .cart').find('.approaching-discount-shipping').find('.approaching-discount-progress-bar-inner').css('width', shippingProgress+'%');
        $('.minicart, .cart').find('.approaching-discount-shipping').find('.approaching-discount-subheader').html(approachingDiscountShippingText);
    }

    // Find any approaching discounts that have their condition met (in which
    // case they are not approaching anymore and won't appear in the parameter
    // "approachingDiscounts")
    items.forEach(function (item) {
        if (item.bonusProductLineItemUUID == 'bonus') {
            item.discountLineItems.forEach(function(discountLineItem) {
                if (discountLineItem.full) {
                    var uuid = discountLineItem.promotion.uuid;
                    promotion.process({
                            promotionid: discountLineItem.promotion.uuid,
                            promotionuuid: uuid,
                            promotionProgress: 100,
                            bonus: {
                                callout: discountLineItem.promotion.callout,
                                heading: discountLineItem.promotion.heading,
                                products: discountLineItem.bonusProducts
                            }
                        },
                        function (item, container) {
                            var conditionmet = container.data('met');
                            if (!conditionmet) {
                                container.data('lineitem-uuid', discountLineItem.uuid);
                                container.data('lineitem-pliuuid', discountLineItem.pliuuid);
                                promotion.enable(container);
                            }
                            if (!container.data('loading')) {
                                container.show();
                            }
                        },
                        function (item) {
                            promotion.add(
                                item.promotionuuid,
                                item.promotionid,
                                item.bonus.callout,
                                item.bonus.heading,
                                100,
                                item.bonus.products,
                                false
                            );

                        }
                    );
                    bonusDiscounts.push(uuid);
                }
            });
        }
    });

    // Remove any approaching discounts that have "fallen off" because the
    // basket total was reduced to below the minimum approach threshhold
    $('.cart').find('[id^=approaching-discount-]').each(function(index, element) {
        var $this = $(this);
        var uuid = $this.attr('id').substring('approaching-discount-'.length);
        if (bonusDiscounts.includes(uuid)) {
            return;
        }
        $this.hide();
    });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 * @param {Object} data - AJAX response from the server
 */
core.updateFreeShippingMessageVisibility = function (el, data) {
   // If shipping is free AKA $0, hide the shipping cost and show the free shipping message, and vice-versa
   if (data.totals.adjustedShippingTotalPrice !== 0) {
        el.find('.free-shipping-message, .single-discount-shipping').addClass('d-none');
        el.find('.single-discount-shipping').next('.single-approaching-discount').addClass('single-discount-border-top');
    } else {
        el.find('.free-shipping-message, .single-discount-shipping').removeClass('d-none');
        el.find('.single-discount-shipping').next('.single-approaching-discount').removeClass('single-discount-border-top');
    }
};

core.updateCartTotals = function (data) {
    var $cart = $('.cart');
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $cart.find('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $cart.find('.tax-total span').empty().append(data.totals.totalTax);
    if ($cart.hasClass('order-summary-column')) { // we are in checkout and not the cart page
        $cart.find('.grand-total .grand-total-sum').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
        $cart.find('.sub-total').empty().append(data.totals.subTotal);
    } else {
        $cart.find('.grand-total span, .checkout-continue .checkout-btn-grand-total').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
        $cart.find('.sub-total span').empty().append(data.totals.subTotal);
    }
    $cart.find('.checkout-continue .checkout-btn').attr('data-price', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);

    var yousaved = $cart.find('.you-saved');
    if (data.saved) {
        yousaved.find('span').empty().append(data.saved);
        yousaved.removeClass('d-none');
    } else {
        yousaved.addClass('d-none');
    }

    core.updateMiniCartCount(data);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $cart.find('.order-discount').removeClass('hide-order-discount');
        $cart.find('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $cart.find('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $cart.find('.shipping-discount').removeClass('hide-shipping-discount');
        $cart.find('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $cart.find('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $cart.find('.giftcertificate-discount').removeClass('d-none');
        $cart.find('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $cart.find('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $cart.find('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $cart.find('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $cart.find('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    core.updateFreeShippingMessageVisibility($cart, data);

    data.items.forEach(function (item) {
        var itemPrice;
        var itemTotalPrice;

        if (item.productType === 'giftCertificate') {
            itemPrice = item.priceTotal.price;
            itemTotalPrice = item.priceTotal.price;
        } else {
            itemPrice = item.renderedPrice;
            itemTotalPrice = item.priceTotal.renderedPrice;
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $cart.find('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $cart.find('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $cart.find('.item-' + item.UUID).empty();
        }

        if (item.productType === 'giftCertificate') {
            $cart.find('.line-item-total-price-amount.item-total-' + item.UUID).empty().append(itemTotalPrice);
        } else {
            $cart.find('.uuid-' + item.UUID + ' .unit-price').empty().append(itemPrice);
            $cart.find('.line-item-price-' + item.UUID + ' .unit-price').empty().append(itemPrice);
            $cart.find('.item-total-' + item.UUID).empty().append(itemTotalPrice);
        }
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
core.validateBasket = function(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);

            core.updateMiniCartCount(data);
            $('#minicartModal').removeClass('show');

            setTimeout(() => {
                $('#minicartModal').modal('hide');
            }, SiteConstants.TransitionSpeed);
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

core.init = function() {
    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var isGiftCard = $(this).data('name').toLowerCase() === "gift card" ? true : false;
        var isBonusProduct = $(this).data('bonus') ? true : false;
        var isCartPage = $('body').find('.page').data('action') === "Cart-Show";
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    core.updateMiniCartCount(data.basket);
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).closest('.card').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts, data.basket.freeShipmentMsg, data.basket.items);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                }

                $('#minicartModal').removeClass('show');
                setTimeout(() => {
                    $('#minicartModal').modal('hide');
                }, SiteConstants.TransitionSpeed);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                // Reload the page after gift card or bonus product removal
                if ((isGiftCard || isBonusProduct) && isCartPage) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                var quantitySelect = $('.quantity[data-uuid="' + uuid + '"]');
                // Update all quantity steppers on the page
                quantitySelect.each(function() {
                    var stepperInput = $(this).siblings('.quantity-stepper').find('input');
                    stepperInput.prop('value', quantity).prop('data-qty', quantity);
                    $(this).val(quantity);
                });
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts, data.freeShipmentMsg, data.items);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts, data.freeShipmentMsg, data.items);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').off('submit').on('submit', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts, data.freeShipmentMsg, data.items);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        $('.coupon-code-field').val('');
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err?.responseJSON?.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts, data.freeShipmentMsg, data.items);
                    module.exports.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts, data.cartModel.freeShipmentMsg, data.cartModel.items);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var fromFirstName = parentContainer.find('.dwfrm_giftCertificate_purchase_fromFirstName-' + uuid).data('value');
        var fromLastName = parentContainer.find('.dwfrm_giftCertificate_purchase_fromLastName-' + uuid).data('value');
        var recipientFirstName = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientFirstName-' + uuid).data('value');
        var recipientLastName = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientLastName-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#fromFirstName').attr('value', fromFirstName);
        modal.find('#fromLastName').attr('value', fromLastName);
        modal.find('#recipientFirstName').attr('value', recipientFirstName);
        modal.find('#recipientLastName').attr('value', recipientLastName);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    // Hide the OR text under checkout button if there are no other payment methods available at the moment (such as paypal)
    $(document).ready(function () {
        if ($('.cartAdditionalPaymentButtons').height() === 0) {
            $('.checkout-continue .or').hide();
        }
    });
}

module.exports = core;
