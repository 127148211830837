'use strict';
var core = require('core/product/tile');

core.methods.swatchSwitch = function(el) {
  var $swatch = el;
  var $tileParent = $swatch.parents('.product-tile');
  var pid = $tileParent.attr('data-pid');
  var $tileImage = $tileParent.find('.product-tile-image');
  var $tileImageHover = $tileParent.find('.product-tile-image-secondary');

  $tileParent.find('.swatch.selected').removeClass('selected');
  $swatch.addClass('selected');

  //Replace main image and secondary image
  if($swatch.data('hover-image') && $swatch.data('hover-image') != '') {
      var swatchImageUrl = $swatch.data('hover-image');
      $tileImage.attr('src', swatchImageUrl);

      if($tileImageHover.length > 0 && $swatch.data('hover-image-secondary')) {
          var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
          $tileImage.data('alt-img-url', swatchSecondaryImageUrl);
          $tileImageHover.attr('src', swatchSecondaryImageUrl);
      }
  }

  //replace main link and Quickview link
  var swatchLink = $swatch.parents('.swatch-link');
  var splitSwatchLink = swatchLink.attr('href').split('?');
  var swatchLinkAttributes = splitSwatchLink[1];

  //main Image Link
  var mainImageLink = $tileParent.find('.product-tile-image-link');
  var mainImageLinkSplit = mainImageLink.attr('href').split('?');
  mainImageLink.attr('href', mainImageLinkSplit[0] + '?' + swatchLinkAttributes);

  //main Quickview Link
  var mainQuickViewLink = $tileParent.find('a.quickview');
  var productTileQuickview = $tileParent.find('a.product-tile-quickview');
  if (mainQuickViewLink.length || productTileQuickview.length) {
      // strip pid from params for quickview (gets added when Storefront URLs are disabled)
      var quickviewSwatchUrl = new URL(swatchLink.attr('href'), window.location.origin);
      quickviewSwatchUrl.searchParams.delete('pid');
      var quickviewQueryString = quickviewSwatchUrl.search.split('?')[1];
      if (mainQuickViewLink.length) {
          mainQuickViewLink.attr('href', mainQuickViewLink.attr('href').split('?')[0] + '?' + 'pid=' + pid + '&' + quickviewQueryString);
      }

      if (productTileQuickview.length) {
          productTileQuickview.attr('href', productTileQuickview.attr('href').split('?')[0] + '?' + 'pid=' + pid + '&' + quickviewQueryString);
      }
  }
};