'use strict';

$(document).ready(function () {
    var maskedNumber = $('.maskedNumber');

    if (maskedNumber.length > 0) {
        var lastFourDigits = maskedNumber.text().trim().slice(-4);
        var maskedDigit = '<span class="maskedPaymentDigit"></span>'
        var fourMaskedDigits = `<span class="mr-1">${maskedDigit}${maskedDigit}${maskedDigit}${maskedDigit}</span>`
        maskedNumber.html(`${fourMaskedDigits} ${fourMaskedDigits} ${fourMaskedDigits} ${lastFourDigits}`);
    }
});
