'use strict';

var base = require('core/components/menu');
base.methods = {};
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('core/utilities/headerUtils');

base.dropdownMenu = function() {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            event.preventDefault();
            var $dropdownLink = $(event.target);

            if(!$dropdownLink.data('lockedAt') || +new Date() - $dropdownLink.data('lockedAt') > 800) { // prevent multi click
                 // Drawer behavior on mobile
                var $dropdownLink = $(event.target);
                var $dropdown = $dropdownLink.closest('.custom-dropdown');
                var $li = $('<li class="dropdown-item top-category" role="button"></li>');
                var $closeMenu = $('<li class="nav-menu"></li>');
                var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                $li.append(link);
                $closeMenu.append($('.close-menu').first().clone());
                $dropdown.children('.dropdown-menu')
                    .prepend($li)
                    .prepend($closeMenu)
                    .attr('aria-hidden', 'false')
                    .find('a').removeAttr('tabindex', '-1');
                $dropdown.addClass('show');
                $dropdownLink.attr('aria-expanded', 'true');
                $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                $(link).focus();
                $('.dropdown-menu').scrollTop(0);
                appendTopCategoryNameToBackButton(event);
            }
            $dropdownLink.data('lockedAt', +new Date());
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });
     // Desktop - close menu on mouseleave
     $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });
};

var appendTopCategoryNameToBackButton = function(e) {
    var $this = $(e.currentTarget);
    var categoryName = '';
    var $dropdownMenu = $this.parent('.custom-dropdown').find('.dropdown-menu');
    var $backButton = $dropdownMenu.find('.close-menu .category-name');
    if ($dropdownMenu.find('.top-category').length > 0) {
        categoryName = $dropdownMenu.find('.top-category').text();
        $backButton.text(categoryName);
    }
}

base.navbarToggler = function() {
  $('.navbar-toggler').click(event => {
      event.preventDefault();
      if ($('body').hasClass('mobile-menu-in')) {
          toggleDrawer('close');
          $('.header-nav').addClass('transparent-nav');
      } else {
          toggleDrawer('open');
          $('.header-nav').removeClass('transparent-nav');
      }
      event.stopPropagation();
      return;
  });

  $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
};

var toggleDrawer = function(status, search=false) {
  var $header = $('header');
  var $headerNav = $header.find('.header-nav');
  var headerNavHeight = headerUtils.getHeaderHeight();
  var $mainMenu = $header.find('.main-menu');
  var $navbar = $mainMenu.find('.navbar-nav');
  var $modalBackground = $('.modal-background');

  if (status === 'open') {
      $('html').scrollTop($headerNav.offset().top);
      $('html').addClass('lock-scroll');
      $('body').addClass('mobile-menu-in');
      $mainMenu
          .addClass('in')
          .attr('aria-hidden', 'false')
          .css('top', headerNavHeight)
          .siblings().attr('aria-hidden', 'true');
      $modalBackground
          .fadeIn(SiteConstants.TransitionSpeed)
          .css('top', headerNavHeight);
      $header
          .siblings().attr('aria-hidden', 'true');
      $navbar
          .find('.nav-link').first().focus();
      if ($('.navbar').height() > $(window).height()) {
          //The 20 is for a little spacing/padding between the last item in the list and this item.
          var ftHeight = $('.navbar').height() - $(window).height()  + headerNavHeight + 20;
          $('.pre-nav-header').css('bottom', ftHeight * -1);
      }
  } else {
      $('body').removeClass('mobile-menu-in');
      $navbar
          .find('.nav-menu, .top-category').detach();
      $navbar
          .find('.thirdCatOpen').removeClass('thirdCatOpen');
      $navbar
          .find('.show').removeClass('show');
      $mainMenu
          .removeClass('in')
          .attr('aria-hidden', 'true')
          .siblings().attr('aria-hidden', 'false');
      $header
          .siblings().attr('aria-hidden', 'false');
      $modalBackground
          .fadeOut(SiteConstants.TransitionSpeed);

      setTimeout(() => {
        $modalBackground.css('top', '');
        $headerNav.addClass('transparent-nav');
        if (!search) {
            $('html').removeClass('lock-scroll');
        }
      }, SiteConstants.TransitionSpeed);
  }
};

base.methods.toggleDrawer = toggleDrawer;
module.exports = base;

