'use strict';

module.exports = function() {
    // Switch form that 'Show Password' button displays on as user switches between login and register tabs so only one is on the page at once
    if (!!document.querySelector(".login-form-nav .tab-content")) {
        // Select the node that will be observed for mutations
        const targetNode = document.querySelector(".login-form-nav .tab-content");

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Create an observer instance
        const observer = new MutationObserver(function (mutationList, observer) {
            if ($('#register').hasClass('active') && $('#login .show-password').length) {
                $('label[for="registration-form-password"]').after($('#login .show-password').clone(true));
                $('#login .show-password').remove();
            } else if ($('#login').hasClass('active') && ('#register .show-password').length) {
                $('label[for="login-form-password"]').after($('#register .show-password').clone(true));
                $('#register .show-password').remove();
            }
        });

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);

        $('#show-password').on('click', function (e) {
            var checkbox = e.target;
            $('#login-form-password, #registration-form-password').each((index, element) => {
                $(element).attr('type', checkbox.checked ? 'text' : 'password');
            });
        });
    }
}
