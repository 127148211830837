'use strict';
const scrollAnimate = require('core/components/scrollAnimate');

/**
 *
 * @description Global data attribute that adds wayfinding scroll events
 */
var addScrollToEventListeners = function() {
    $('body').on('click', '[data-scroll-to]', function(e) {
        e.preventDefault();
        var anchorID = $(this).data('scrollTo');
        if ($(`#${anchorID}`).length > 0) {
            scrollAnimate($(`#${anchorID}`));
        } else if ( $(`[data-scroll-to-target="${anchorID}"]`).length > 0 ) {
            scrollAnimate($(`[data-scroll-to-target="${anchorID}"]`).eq(0));
        }
    });
};

module.exports = {
    addScrollToEventListeners: addScrollToEventListeners
}