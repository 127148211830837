'use strict';

var base = require('base/checkout/formErrors');
var core = require('core/checkout/formErrors');

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        var formField = $('*[name=' + attr + ']', parentSelector);
        formField.addClass('is-invalid')
        .siblings('.invalid-feedback')
        .html(fieldErrors[attr]);

        // Add invalid class to labels as well
        var label = $(`label[for="${formField.attr('id')}"]`);
        label.addClass('is-invalid');
    });

    var updateAddressButton = $(parentSelector).find('.btn-show-details');
    if (updateAddressButton.length > 0) {
        updateAddressButton.trigger('click');
    }

    // Animate to top of form that has errors
    scrollAnimate($(parentSelector));
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    //default clientSideValidation.js will remove .is-invalid only for html5 errors
    //let our manual call here clear all messaging on .invalid-feedback always
    $(parentSelector).find('.is-invalid').removeClass('is-invalid');
    $(parentSelector).find('.invalid-feedback').html('');
    $('.error-message').hide();
}

base.loadFormErrors = loadFormErrors;
core.loadFormErrors = loadFormErrors;
base.clearPreviousErrors = clearPreviousErrors;
core.clearPreviousErrors = clearPreviousErrors;

module.exports = base;
module.exports = core;
