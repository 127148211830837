'use strict';

var SiteConstants = require('constants/SiteConstants');
var updateMiniCart = true;
var cart = require('./../cart/cart');
var updateMiniCartCount = cart.updateMiniCartCount;
var abSlider = require('./slider');

function setMiniCartBodyMaxHeight(minicart) {
    $(minicart).css({
        '--minicart-header-height': $('.minicart-header').outerHeight() + 'px',
        '--minicart-footer-height': $('.minicart-footer').outerHeight() + 'px'
    })

}

function handleMiniCartEvent() {
    if(!$('header').hasClass('checkout-header')) {
        var url = $('.minicart').data('action-url');
        $('#minicartModal').modal();
        var miniCartPopulated = $('.minicart .modal .minicart-content').children().length > 0 ? true : false;

        if (!miniCartPopulated || updateMiniCart) {
            $('.minicart .modal .modal-drawer').spinner().start();
            $.get(url, data => {
                $('.minicart .modal .modal-content .minicart-content').empty().append(data);
                updateMiniCart = false;
                $.spinner().stop();
                $('body').trigger('minicart:loaded', $('.minicart .modal'));
                $('body').trigger('minicart:slider', $('.minicart .modal'));
                setMiniCartBodyMaxHeight($('.minicart .modal'));
            });
        } else {
            var $modal = $('.minicart .modal');
            // Reinitialize mobile modal sliders to prevent tiny slider error on drag
            var $modalSlider = $modal.find('.slider-container .slider');
            if ($modalSlider.length) {
                $modalSlider.trigger('slider:destroy');
                abSlider.initializeSliders($modal);
            }
        }
    }
}

module.exports = function () {
    $('.minicart').on('count:update', (event, count) => {
        updateMiniCartCount(count.cart);
    });

    $('.minicart-link').on('click touchstart', (e) => {
        e.preventDefault();
        if(!$('header').hasClass('checkout-header')) {
            handleMiniCartEvent();
        } else if ($('header').hasClass('checkout-header')) {
            window.location.href = $(e.currentTarget).attr('href');
        }
    });

    $('body').on('touchstart click', '.minicart', event => {
        if ($('.minicart .modal-drawer').has(event.target).length <= 0 && $('.minicart .minicart-total').has(event.target).length <= 0) {
            $('#minicartModal').removeClass('show');

            setTimeout(() => {
                $('#minicartModal').modal('hide');
            }, SiteConstants.TransitionSpeed);
        }
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('cart:update', () => {
        updateMiniCart = true;
        setMiniCartBodyMaxHeight($('.minicart .modal'));
    });

    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
        handleMiniCartEvent();
    });

    $('body').on('minicart:loaded cart:afterUpdate', (event, minicart) => {
        setMiniCartBodyMaxHeight(minicart);
    });

};