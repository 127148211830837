'use strict';
var base = require('./base');

function initializeShowHideSwatches() {
  $('body').on('quickview:ready', () => {
      base.showHideSwatches();
  });
}

module.exports = {
  initializeShowHideSwatches: initializeShowHideSwatches
}