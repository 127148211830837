'use strict';
var integrations = require('integrations/product/base');
var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();

/**
 * Retrieves the bonus discount line item UUID, if it exists (for the gift-with-purchase feature)
 * @param {jquery} $el - DOM container to search in
 * @return {string} - UUID of the bonus line item
 */
integrations.methods.getGwpBonusDiscountLineItemUUID = function($el) {
    return $el.find('.gwp-uuid').val();
}

/**
 * Retrieves the UUID of the product line item assoiated with the bonus discount line item (for the gift-with-purchase feature)
 * @param {jquery} $el - DOM container to search in
 * @return {string} - UUID of the associated product line item
 */
integrations.methods.getGwpProductLineItemUUID = function($el) {
    return $el.find('.gwp-pliuuid').val();
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
integrations.methods.getPidValue = function($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').data('pid');
    } else  if (($el).hasClass('single-variant-quick-add-to-cart')) {
        pid = $($el).data('pid');
    } else  if (($el).parents('.popover').length) {
        pid = $($el).closest('.product-detail.product-quick-add-to-cart').data('pid');
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
integrations.methods.processSwatchValues = function(attr, $productContainer, msgs) {

    attr.values.forEach(function (attrValue) {
        // This will target both color and salecolor, when the attribute is salecolor
        //   - to target only salecolor, we need to add different markup to the
        //     element for $attrValue, e.g. [data-attr-ex-'salecolor'] [data-attr-value='(attrValue.value)']
        var attrid = attr.id == 'salecolor' ? 'color' : attr.id;
        var $attrValue = $productContainer.find('[data-attr="' + attrid + '"] [data-attr-value="' + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        $attrValue.addClass(attrValue.available ? 'available' :  toggleObject.viewOutOfStockItems ? 'out-of-stock' : 'unavailable');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });

    var $colorSwatchList = $productContainer.find('.color-swatch-list');
    var $saleColorSwatchList = $productContainer.find('.sale-color-swatch-list');
    if (attr.attributeId == 'color') {
        if ($colorSwatchList.find('.swatch.selected').length > 0) {
            $productContainer.find('.salecolor-display-value').text('');
            $productContainer.find('.color-display-value').text(attr.displayValue || '');
        }
    }

    if (attr.attributeId === 'salecolor') {
        if ($saleColorSwatchList.find('.swatch.selected').length > 0) {
            $productContainer.find('.color-display-value').text('');
            $productContainer.find('.salecolor-display-value').text(attr.displayValue || '');
        }
    };

    if (attr.attributeId == 'size') {
        $productContainer.find('[data-attr="size"]').find('.non-color-display-value').text(attr.displayValue || '');
    };
}

integrations.setSwatchText = function() {
    var $colorSwatchList = $('.color-swatch-list');
    var $saleColorSwatchList = $('.sale-color-swatch-list');
    if ($colorSwatchList.find('.swatch.selected').length > 0) {
        $('.salecolor-display-value').text('');
    } else if ($saleColorSwatchList.find('.swatch.selected').length > 0) {
        $('.color-display-value').text('');
    }
}

integrations.sizeChart = function() {
    $('body').on('click', '.size-chart .size-chart-launcher', event => {
        event.preventDefault();
        var url = $(event.target).attr('data-url-ajax');
        var $sizeChartModal = $('#sizeChartModal').eq(0);
        var $modalBody = $sizeChartModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);
            }
        });

        //if the sizechart is from a quickview append after all the modal-backdrops
        if ($(event.target).parents('.product-quickview').length) {
            var $sizeChartContainer = $(event.target).closest('.size-chart');

            $sizeChartModal.appendTo('body');
            $sizeChartModal.on('hide.bs.modal', event => {
                $sizeChartModal.appendTo($sizeChartContainer);
            });
        }
        $sizeChartModal.modal('show');
    });

    $('body').on('click', '#sizeChartModal .close', event =>  {
        $(event.target).closest('#sizeChartModal').modal('hide');
    });
}

integrations.showHideSwatches = function() {
    if ($('.swatch-color-veil').length === 0) {
        return;
    }
    var $productDetail = $('.product-detail');
    var isQuickView = $productDetail.hasClass('product-quickview');
    var $swatchList = $productDetail.find('.swatch-color-veil').find('.color-attribute');
    var cutOffNumber;
    if (window.isMobile()) {
        cutOffNumber = 7;
    } else if (isQuickView) {
        cutOffNumber = 9
    } else {
        cutOffNumber = 11
    }
    //Product set has multiple products shown so we must make sure we are reading/updating each product shown.
    $('.product-detail-attributes').each(function () {
        var $swatchList = $(this).find('.swatch-color-veil').find('.color-attribute');
        if ($swatchList.length > cutOffNumber) {
            var $swatchCutOff = $swatchList.eq(cutOffNumber - 1);
            var $swatchShowTrigger = $(this).find('.js-swatch-show');
            var $swatchHideTrigger = $(this).find('.js-swatch-hide');
            var $count = $swatchList.length - cutOffNumber;

            $swatchShowTrigger.text(`+${$count}`);
            $swatchHideTrigger.removeClass('d-none');
            $swatchCutOff.nextAll('.color-attribute').addClass('swatch-veiled');
        }
    });

    $('.js-swatch-show').on('click', function() {
        $(this).parent('.swatch-color-veil').find('.swatch-veiled').removeClass('d-none');
        $(this).addClass('d-none');
        $(this).siblings('.js-swatch-hide').removeClass('d-none');
    });

    $('.js-swatch-hide').on('click', function() {
        $(this).parent('.swatch-color-veil').find('.swatch-veiled').addClass('d-none');
        $(this).addClass('d-none');
        $(this).siblings('.js-swatch-show').removeClass('d-none');
    });
}

integrations.observeYotpoAdd = function() {
    if (!!document.getElementById("yotpo-reviews-top-div")) {
        const targetNode = document.getElementById("yotpo-reviews-top-div");
        const config = { childList: true, subtree: true };
        const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
            if (mutation.type === "childList") {
                if ( !!mutation.target.parentNode.querySelector('.reviews-amount') ) {
                    integrations.methods.updateReviewCount();
                    observer.disconnect();
                }
            }
        }
        };
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }
};

integrations.methods.updateReviewCount = function() {
    if ( $('#yotpo-reviews-top-div').length ) {
        var $numOfReviews = $('.product-detail-review-cta').find('.number-of-reviews');
        var $yotpoNumberText = $('#yotpo-reviews-top-div').find('.reviews-amount').text();

        if  ($yotpoNumberText !== '' && !$numOfReviews.parents('.product-quickview').length ) {
            $numOfReviews.text($yotpoNumberText);
        }
    }
}

integrations.updateAttribute = function() {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        response.container.find('#sizeChartModal').attr('data-product', response.data.product.id);

        //Quickview
        if ($('.modal.show .product-quickview>.bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id.split(" ")[0]);
        } else if ($('.set-items').length) {
            response.container.find('.product-id').text(response.data.product.id.split(" ")[0]);
        } else if ($('.modal.show .product-quickview').length) {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
            $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id.split(" ")[0]);
        //Main PDP
        } else if ($('.product-detail>.bundle-items').length) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id.split(" ")[0]);
        } else if ($('.product-set-detail').eq(0)) {
            response.container.data('pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id.split(" ")[0]);
            response.container.find('.add-to-cart').data('pid', response.data.product.id);
        } else {
            $('.product-detail .add-to-cart').data('pid', response.data.product.id);
            $('.product-id').text(response.data.product.id.split(" ")[0]);
            $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
        }
    });
}

/**
 * Updates slider for PDP main images and thumbnails from response containing images
 * EXCLUDE the PWP sliders in the buystack
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
integrations.methods.createSlider = function(images, assets, $productContainer) {
    var $sliderContainers = $productContainer.find('.slider-container:not(.slider-container-minicart)');
    var data = images !== null ? {images} : null;
    data.assets = assets || null;

    // Reversing order in which to update sliders so that thumbnails get initialized first
    $($sliderContainers.get().reverse()).each((index, sliderContainer) => {
        var $slider = $(sliderContainer).find('.slider');
        $slider.trigger('slider:update', data);
    });
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
integrations.methods.getChildProducts = function() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).data('pid'),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**********
    * shared across QV and PDP
*/
integrations.addToCart = function () {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).data('pid'),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var gwpUUID;
        var gwpPLIUUID;
        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
                gwpUUID = scope.methods.getGwpBonusDiscountLineItemUUID($productModalbody);
                gwpPLIUUID = scope.methods.getGwpProductLineItemUUID($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
            gwpUUID = scope.methods.getGwpBonusDiscountLineItemUUID($productContainer);
            gwpPLIUUID = scope.methods.getGwpProductLineItemUUID($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer)
        };

        if (gwpUUID && gwpPLIUUID) {
            form.quantity = form.quantity || 1;

            var pids = JSON.stringify({
                totalQty: parseInt(form.quantity),
                bonusProducts: [{
                    qty: parseInt(form.quantity),
                    pid: form.pid,
                    options: form.options
                }]
            });
            var prefix = (addToCartUrl.indexOf('?') < 0) ? '?' : '&';
            addToCartUrl += `${prefix}uuid=${gwpUUID}&pliuuid=${gwpPLIUUID}&pids=${pids}`;
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

module.exports = integrations;
